@charset "utf-8";
@import '../../setting';
@import '../../mixin';

/*
top
*/
$id: top;
$cmnPath:'../../../img/common/';
$imgPath:'../../../img/#{$id}/';

/* ==[ Common ]==================================== */
/* Emphasis character / 強調文字 */
.em_600 {
  font-weight: 600
}

.em_700 {
  font-weight: 700
}

.em_800 {
  font-weight: 800
}

.em_900 {
  font-weight: 900
}

/* margin / 余白 */
.mb50 {
  margin-bottom: 50px;
}

.mb60 {
  margin-bottom: 60px;
}

.mb150 {
  margin-bottom: 150px;
}

.title-box {
  text-align: center;
  margin-bottom: 60px;

  @include sphoneP {
    margin-bottom: 30px;
  }
}

.sec-title {
  color: #0071be;
  font-family: 'Noto Serif JP', serif;
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 20px;

  @include sphoneP {
    font-size: 24px;
  }
}

.sec-subtitle {
  color: #0071be;
  font-family: 'Montserrat', sans-serif;
}

.en {
  color: #141414;
  font-family: 'Montserrat', sans-serif;
  font-size: 36px;
  font-weight: normal;
  margin-bottom: 10px;

  &::after {
    border-bottom: 3px #0071be solid;
    border-radius: 10px;
    content: "";
    display: block;
    width: 30px;
    margin: 0 auto;
  }
}

.ja {
  color: #141414;
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 20px;
}

.noto-sans {
  font-family: 'Noto Sans JP',
    sans-serif !important;
}

.link-btn {
  color: #0071be !important;
  border: 1px #0071be solid;
  display: block;
  padding: 10px;
  margin: 0 auto;
  text-align: center;
  width: 385px;
  max-width: 100%;
  position: relative;
  overflow: hidden;
  text-transform: uppercase;

  &::after {
    content: "";
    border-top: 2px solid #0071be;
    border-right: 2px solid #0071be;
    display: block;
    width: 6px;
    height: 6px;
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translate(0, -50%) rotate(45deg);
  }

  span {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-radius: 50%;
    background-color: #0071be;
    -webkit-transition: width 0.4s ease-in-out, height 0.4s ease-in-out;
    transition: width 0.4s ease-in-out, height 0.4s ease-in-out;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: -1;
  }

  &:hover {
    color: #fff !important;
    color: tint(#fff, 75%);
    opacity: 1;
    z-index: 1;
    text-decoration: none;

    &::after {
      border-top: 2px solid #fff !important;
      border-right: 2px solid #fff !important;
    }

    span {
      width: 225%;
      height: 562.5px;
    }
  }
}

.link-item {
  width: 100%;
}

.invisible {
  visibility: hidden;
}

/* ==[ .mainvisual ]==================================== */
.mainvisual {
  margin-top: 60px;

  @include tabletL {
    margin-top: 45px;
  }

  /* ::: スライダー ::: */
  .mv-slider .slick-slide img {
    width: 100%;
    max-width: 100%;
  }

  .slick-prev:before,
  .slick-next:before {
    opacity: 1;
    color: #000;
    font-size: 24px;
  }

  /* Slick Dots */
  .mv-slider {

    .slick-dotted.slick-slider {
      margin-bottom: 0;
    }

    .slide-dots {
      position: absolute;
      bottom: 40px;
      left: 50%;
      transform: translate(-40%, 0);
      list-style: none;
      display: block;
      text-align: left;
      padding: 0;
      margin: 0;
      width: 100%;

      @include tabletL {
        bottom: 30px;
        transform: translate(-45%, 0);
      }

      @include sphoneP {
        bottom: 0;
      }

      li {
        position: relative;
        display: inline-block;
        height: 20px;
        width: 70px;
        margin: 0 3px;
        padding: 0;
        cursor: pointer;

        @include tabletP {
          width: 35px;
        }

        button {
          border-radius: 2px;
          background: #fff;
          position: relative;
          text-indent: -9999px;
          width: 70px;
          height: 3px;

          @include tabletP {
            width: 35px;
          }

          &:before {
            content: "";
            font-size: 0;
            text-indent: 0px;
            position: absolute;
            top: 0;
            left: 0;
          }

          &:hover,
          &:focus {
            outline: none;
          }

          &:hover:before,
          &:focus:before {
            opacity: 1;
          }
        }

        &.slick-active button:before {
          border-radius: 2px;
          background: #0071be;
          width: 70px;
          height: 3px;

          @include tabletP {
            width: 35px;
          }
        }
      }
    }

    .slick-dotted.slick-slider {
      margin-bottom: 0 !important;
    }

  }

  .newsList-wrap {
    background-color: #e5f1f8;
    padding: 10px 20px;
    width: 60%;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 100;

    @media screen and (max-width:80em) {
      width: 100%;
      bottom: -40px;
    }

    @include tabletL {
      bottom: -40px;
      width: 100%;
    }

    @include sphoneP {
      bottom: -70px;
    }

    @media screen and (max-width:30em) {
      bottom: -100px;
    }


    .news-list {
      display: flex;
      align-items: center;

      @include sphoneP {
        flex-wrap: wrap;
      }

      li {
        font-size: 16px;
        display: inline-block;
        flex-grow: 0;

        @include pc {
          font-size: 14px;
        }

        &:first-child a {
          display: flex;

          @include tabletP {
            flex-wrap: wrap;
          }
        }

        a {
          color: #343434;
          display: inline-block;

          &:hover {
            opacity: .6;
          }
        }
      }

      .post-date {
        font-weight: 400;
        position: relative;
        margin-right: 10px;

        &::before {
          content: "お知らせ";
          color: #0071be;
          border: 1px #0071be solid;
          font-size: 12px;
          padding: 3px 10px;
          margin-right: 20px;

          @include sphoneP {
            padding: 0 10px;
            margin-right: 10px;
          }
        }
      }

      .post-cont {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .list-link {
        padding-right: 30px;
        position: relative;

        &::after {
          background: url(../../../img/icon/icon_list_off.svg) no-repeat center center;
          background-size: 21px auto;
          content: "";
          display: inline-block;
          width: 21px;
          height: 11px;

          position: absolute;
          top: 50%;
          right: 0;
          transform: translate(0, -50%);
        }

        &:hover {
          color: #0071be;

          &::after {
            background: url(../../../img/icon/icon_list_on.svg) no-repeat center center;
          }
        }
      }
    }
  }

}

/* ==[ .commitment ]==================================== */
.commitment {
  .sec-inner {
    padding: 80px 0;
    background: rgb(229, 241, 248);
    background: linear-gradient(0deg, rgba(229, 241, 248, 1) 0%, rgba(229, 241, 248, 1) 50%, rgba(255, 255, 255, 1) 50%);

    @include tabletL {
      margin-left: 15px;
      margin-right: 15px;
    }

    @include sphoneP {
      padding-top: 100px;
    }
  }

  .vertical-line {
    margin: 30px auto;

    @include sphoneP {}
  }

  .card {
    align-items: center !important;
    flex-direction: row;
    border: none;
    border-radius: 0;
    background: rgb(229, 241, 248);
    background: linear-gradient(0deg, rgba(229, 241, 248, 1) 0%, rgba(229, 241, 248, 1) 86%, rgba(255, 255, 255, 1) 86%);
    padding: 0 50px;
    position: relative;

    @include tabletL {
      background: rgb(229, 241, 248);
      background: linear-gradient(0deg, rgba(229, 241, 248, 1) 0%, rgba(229, 241, 248, 1) 94%, rgba(255, 255, 255, 1) 94%);
      flex-direction: column;
      padding: 100px 0 30px;
    }

    .number-img {
      margin-bottom: 0;
      position: absolute;
      top: 0;
      right: 30px;
      width: auto;
      max-width: 100%;

      @include sphoneP {
        right: 15px;
        width: 150px;
      }
    }

    .card-img {
      width: 480px;
      max-width: 100%;

      @include pc {
        width: 360px;
        transition: 0.3s;
      }

      @include tabletL {
        width: 600px;
      }

      @include sphoneP {
        padding: 0 15px;
      }
    }

    .card-title {
      color: #0071be;
      font-weight: 600;
      margin-bottom: 20px;

      @include tabletL {
        text-align: center;
      }

      @include sphoneP {
        font-size: 18px;
      }

    }

    .card-body {
      padding-top: 80px;
      width: 100%;
      max-width: 100%;

      @include tabletP {
        padding-top: 30px;

      }
    }
  }

  .slider {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 60px;

    .slick-prev,
    .slick-next {
      width: 25px;
      height: 25px;
      opacity: 1 !important;
      z-index: 1;
    }

    .slick-prev {
      left: 8.5%;

      @include tabletL {
        left: -15px;
      }
    }

    .slick-next {
      right: 11%;

      @include tabletL {
        right: 0;
      }
    }

    .slick-prev::before,
    .slick-next::before {
      content: "";
      display: inline-block;
    }

    .slick-prev::before {
      display: inline-block;
      width: 25px;
      height: 25px;
      margin: 0 10px;
      border-left: 4px solid rgba(52, 52, 52, .6);
      border-bottom: 4px solid rgba(52, 52, 52, .6);
      transform: rotate(45deg);
      opacity: 1;
    }

    .slick-next::before {
      display: inline-block;
      width: 25px;
      height: 25px;
      margin: 0 10px;
      border-top: 4px solid rgba(52, 52, 52, .6);
      border-right: 4px solid rgba(52, 52, 52, .6);
      transform: rotate(45deg);
      opacity: 1;
    }
  }

  .slick-slide {
    margin: 0;

    img {
      width: 100%;
      height: auto;
    }

  }

  .slider-container {
    display: none !important;
    overflow: hidden;
    position: relative;

    &.initialized {
      display: block;
    }
  }

  .link-btn {
    @include sphoneP {
      width: 90%;
    }
  }
}

/* ==[ .service ]==================================== */
.service {
  .sec-inner {
    padding-top: 100px;
  }

  .card {
    border: none;

    .img-box {
      height: 100%;
      height: auto;
    }

    .card-img-top {
      border: 1px #dcdcdc solid;
      width: 100%;
      max-width: 100%;
    }

    .card-body {
      padding: 20px 0;
    }

    .title-box {
      border-bottom: 1px #fff solid;
      margin-bottom: 0;
      text-align: left;
      margin-bottom: 20px;

      &:hover {
        border-bottom: 1px #0071be solid;
        text-decoration: none;
      }
    }

    .catd-title {
      color: #0071be;
      line-height: normal;

      @include sphoneP {
        font-size: 18px;
      }

      span {
        font-family: 'Montserrat', sans-serif;
        font-size: 18px;
        padding-left: 10px;

        @include sphoneP {
          font-size: 14px;
        }

        &::before {
          content: "/";
          display: inline-block;
          padding-right: 10px;
        }
      }
    }

    .card-footer {
      background-color: #fff;
      border-top: none;
      text-align: center;
    }
  }

}

/* ==[ .case ]==================================== */
.case {

  .sec-inner {
    padding-top: 0;
  }

  .nav-tabs {
    border-bottom: 3px #0071be solid;

    .nav-link {
      position: relative;

      &.active {
        background-color: #0071be;
        border-color: #0071be;
        border-radius: 0;
        color: #fff;
        font-size: 24px;
        padding: 0 50px;

        @include sphoneP {
          font-size: 20px;
        }

        &::after {
          content: "";
          display: inline-block;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 15px 15px 0 15px;
          border-color: #0072be transparent transparent transparent;
          position: absolute;
          bottom: -15px;
          left: 50%;
          transform: translate(-50%, 0);
        }
      }

    }
  }

  .tab-content {
    border-bottom: 3px #0071be solid;
    background: url(../../../img/top/case/case_bg.jpg) no-repeat center center;
    background-size: cover;
    content: "";
    margin-bottom: 60px;

    .tab-pane {
      padding: 70px 0;
    }

    .link-item {
      -moz-box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
      -webkit-box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
      -ms-box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
      margin-bottom: 30px;
      transition: transform 0.15s linear;

      &:hover {
        -moz-box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.3);
        -ms-box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.3);
        box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.3);
        transform: scale(1.03);
        opacity: 1;
      }
    }
  }

  .link-box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    max-width: 100%;

    @include sphoneP {
      flex-wrap: wrap;
    }

    li {
      margin: 0 30px 20px;

      @include tabletP {
        width: 100%;
      }
    }
  }
}

/* ==[ .topics ]==================================== */
.topics {

  .sec-inner {
    padding-top: 0;
  }

  .title-box {
    text-align: left;
  }

  .sec-title {
    color: #343434;
    font-size: 24px;
    font-family: 'Noto Sans JP', sans-serif;

    span {
      font-family: 'Montserrat', sans-serif;
      font-size: 18px;
      font-weight: normal;
      padding-left: 10px;

      &::before {
        content: "/";
        display: inline-block;
        padding-right: 10px;
      }
    }
  }

  .link-item {
    margin-bottom: 30px;
    border: 2px #dcdcdc solid;
  }
}

/* ==[ .vision ]==================================== */
.vision {
  .sec-inner {
    padding-top: 0;
  }

  .img-box {
    margin-bottom: 80px;
    position: relative;

    .vertical-line {
      display: block;
      max-width: 1px;
      position: absolute;
      bottom: -35px;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
}

/* ==[ .contact ]==================================== */
.contact {
  background-color: #00349d;
  color: #fff;
  padding: 60px 0;
  position: relative;

  .sec-title {
    color: #343434;
    font-size: 24px;
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: normal;
    color: #fff;

    span {
      font-family: 'Montserrat', sans-serif;
      font-size: 18px;
      font-weight: normal;
      padding-left: 10px;

      &::before {
        content: "/";
        display: inline-block;
        padding-right: 10px;
      }
    }
  }

  .context {
    margin-bottom: 20px;
  }


  .contact-btn {
    background-color: #ffda06;
    border-radius: 25px;
    color: #343434;
    display: block;
    text-align: center;
    padding: 5px;
    width: 380px;
    max-width: 100%;
    position: relative;

    &::after {
      content: "";
      border-top: 2px solid #343434;
      border-right: 2px solid #000;
      display: inline-block;
      width: 8px;
      height: 8px;
      position: absolute;
      top: 50%;
      right: 25px;
      transform: translate(0, -50%) rotate(45deg);
    }

    &:hover {
      text-decoration: none;
    }
  }
}

@media screen and (max-width: 36em) {
  .contact-btn {
    margin: 0 auto;
  }
}

/* ==[ 下層ページ共通 ]==================================== */
h2.page-sectitle {
  position: relative;
  font-family: 'Noto Serif JP',
    serif;
  font-size: 30px;
  color: #0071BE;
  padding: 10px;
  margin-bottom: 30px;
  text-align: center;

  @include sphoneP {
    font-size: 30px;
  }

  &::after {
    border-bottom: 3px #0071be solid;
    border-radius: 10px;
    content: "";
    display: block;
    width: 30px;
    margin: 10px auto 0;
  }

  @include sphoneP {
    font-size: 24px;
  }
}

h3.page-sectitle {
  background-color: #2075BC;
  color: #fff;
  font-size: 26px;
  padding: 10px;
  padding-left: 20px;
  font-family: 'Noto Sans JP',
    sans-serif;
  font-weight: bold;
  text-align: left;
  margin-bottom: 30px;

  @include sphoneP {
    font-size: 22px;
  }
}

.page-header {
  margin-top: 80px;

  @include tabletL {
    margin-top: 45px;
  }
}

.jumbotron {
  padding: 0 !important;

  .page-header-bg {
    margin: 0 auto;
    width: 100%;
    max-width: 100%;
  }

  .object-fit-img {
    @include tabletP {
      height: 160px;
    }
  }

  .container-fluid {
    padding: 15px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .page-title {
    color: #fff;
    font-size: 36px;
    font-weight: 700;
    text-align: left;

    @include sphoneP {
      font-size: 28px;
    }
  }
}

.anchor-link-wrap {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  li {
    box-sizing: border-box;
    width: 33.3333%;
    padding: 0 5px;

    @include sphoneP {
      width: 50%;
    }
  }

  .anchor-link {
    border: 1px #0071BE solid;
    color: #0071BE;
    display: inline-block;
    padding: 10px;
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
    position: relative;

    @include sphoneP {
      font-size: 12px;
      text-align: left;
      margin-bottom: 10px;
      padding: 10px 5px;
    }

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: 20px;
      width: 10px;
      height: 10px;
      border-top: 2px solid #0071BE;
      border-right: 2px solid #0071BE;
      transform: translate(0, -50%) rotate(135deg);

      @include sphoneP {
        right: 5px;
        width: 8px;
        height: 8px;
      }
    }

    &.active {
      background-color: #0071BE;
      color: #fff;

      &::after {
        border-color: #fff;
      }
    }

    &:hover {
      text-decoration: none;
    }
  }
}

table.type01 {
  border-collapse: collapse;
  border: 1px #0071BE solid;
  width: 100%;
  max-width: 100%;
  display: table;
  table-layout: fixed;

  @include sphoneP {
    border: none;
  }

  th,
  td {
    padding: 50px 20px;

    @include sphoneP {
      display: block;
      padding: 10px;
    }
  }

  th {
    background-color: #2075BC;
    border-bottom: 1px #fff solid;
    color: #fff;
    width: 30%;

    @include sphoneP {
      border-color: #0071BE;
      text-align: center;
      width: 100%;
      max-width: 100%;
    }

    &.last {
      border-bottom: none;
    }
  }

  td {
    border: 1px #0071BE solid;
  }
}

.price-area {
  display: flex;
  align-items: center;

  @include sphoneP {
    justify-content: center;
  }

  .tag {
    border: 3px #E4F1F9 solid;
    color: #0071BE;
    display: inline-block;
    font-size: 20px;
    padding: 0 10px;
    text-align: center;
    margin-right: 20px;

    @include sphoneP {
      font-size: 18px;
    }
  }

  .price {
    color: #0071BE;
    display: inline-block;
    font-size: 30px;
    font-weight: 600;

    @include sphoneP {
      font-size: 26px;
    }
  }
}

/* ==[ #works / 施工事例一覧 ]==================================== */
#works {
  .img-box {
    margin-bottom: 130px;
  }

  .apartment {
    .sec-inner {
      padding-bottom: 240px;

      @include sphoneP {
        padding-bottom: 100px;
      }
    }
  }
}

/* ==[ #guide / 初めての方へ ]==================================== */
#guide {

  .important {
    .card {
      border: none;
      margin-bottom: 10px;

      @include sphoneP {
        flex-direction: row;
        margin-bottom: 30px;
      }
    }

    .card-img-top {
      border: 1px #707070 solid;

      @include sphoneP {
        width: 100%;
        max-width: 130px;
        height: 160px;
      }
    }

    .card-body {
      padding: 20px 0;

      @include sphoneP {
        padding: 0 10px;
      }
    }

    .card-title {
      color: #0071BE;
      font-weight: 600;

      @include sphoneP {
        font-size: 18px;
      }
    }

    .card-text {
      font-size: 18px;

      @include sphoneP {
        font-size: 16px;
      }
    }
  }

  .reason {
    .sec-inner {
      padding-top: 0;

      &.primary {
        padding-bottom: 0;

        .container-fluid {
          background: #fff
        }
      }
    }

    .container-full {
      position: relative;

    }

    .bg-area {
      display: block;
      width: 50%;
      height: 85%;
      position: absolute;
      bottom: 0;

      &.right {
        background: linear-gradient(90deg, white 0%, white 50%, rgba(229, 241, 248, 1) 50%, rgba(229, 241, 248, 1) 100%);
        right: 0;
        padding-bottom: 0;
      }

      &.left {
        background: linear-gradient(90deg, rgba(229, 241, 248, 1) 0%, rgba(229, 241, 248, 1) 50%, white 50%, white 100%);
        left: 0;
        padding-bottom: 0;
      }

      @include tabletL {

        &.left,
        &.right {
          display: none;
        }
      }
    }

    .container-fluid {
      position: relative;
      padding-bottom: 50px;
      background: rgb(229, 241, 248);
      background: linear-gradient(0deg, rgba(229, 241, 248, 1) 0%, rgba(229, 241, 248, 1) 85%, rgba(255, 255, 255, 1) 85%);

      @include tabletL {
        background: linear-gradient(0deg, rgba(229, 241, 248, 1) 0%, rgba(229, 241, 248, 1) 100%, rgba(255, 255, 255, 1) 100%);
        padding-top: 30px;
        padding-bottom: 30px;
        margin-left: 15px !important;
        width: calc(100% - 30px);
      }

      .row {
        align-items: flex-end;

        @include tabletP {
          align-items: center;
        }
      }

    }

    .reason-img {
      width: 490px;
      max-width: 100%;

      @include tabletP {
        margin-bottom: 20px;
      }
    }

    .number-img {
      position: absolute;
      top: 5px;

      @include tabletL {
        top: -30px;
        width: 150px;
      }

      &.right {
        right: 0;
      }

      &.left {
        @include tabletP {
          right: 0;
        }
      }
    }

    .card-title {
      color: #0071BE;
      font-weight: 600;
      margin-bottom: 10px;

      @include tabletP {
        text-align: center;
      }
    }

    .card-text {
      font-size: 18px;
    }

    .award {
      .container-fluid {
        background: transparent
      }

      .card {
        border: 5px #E4F1F9 solid;
        flex-direction: row;
        padding: 50px;

        @include tabletL {
          flex-direction: column;
        }

        @include sphoneP {
          padding: 20px 15px;
        }
      }

      .card-img {
        width: 45%;
        height: auto;
        height: 100%;

        @include tabletL {
          width: 400%;
          margin: 0 auto 30px;
        }
      }

      .card-body {
        padding: 0 20px;

        @include sphoneP {
          padding: 0;
        }
      }

      .card-title {
        color: #0071BE;
        font-weight: 600;
        margin-bottom: 20px;
      }

      .card-text {
        margin-bottom: 50px;
      }
    }
  }

  .flow {
    .row:not(:first-child) {
      background-color: #E4F1F9;
      margin-bottom: 50px;
      position: relative;
      padding: 10px 0;

      @include sphoneP {
        padding: 20px 0;
        margin-left: 15px;
        margin-right: 15px;
      }

      &::after {
        content: "";
        display: inline-block;
        width: 25px;
        height: 25px;
        margin: 0;
        border-bottom: 2px solid #707070;
        border-right: 2px solid #707070;
        position: absolute;
        left: 50%;
        bottom: -30px;
        transform: translate(-50%, 0) rotate(45deg);
      }

      &.last {
        &::after {
          display: none;
        }
      }
    }

    .flow-number {
      color: #00349D;
      font-family: 'Roboto',
        sans-serif;
      font-size: 55px;

      @include sphoneP {
        font-size: 45px;
      }
    }

    .flow-title {
      color: #0071BE;
    }

    .context {
      font-size: 20px;
      line-height: normal;

      @include sphoneP {
        font-size: 18px;
      }
    }
  }

  .faq {
    .accordion_menu {
      border: 5px #E4F1F9 solid;
      padding: 20px;
      width: 100%;
      margin-bottom: 20px;

      @include sphoneP {
        padding: 15px;
      }

      >li {
        margin-bottom: -1px;
        padding-left: 60px;
        position: relative;

        @include sphoneP {
          padding-left: 40px;
        }

        &::before {
          content: "Q";
          color: #0071BE;
          border: 2px #0071BE solid;
          border-radius: 50%;
          font-size: 30px;
          line-height: 50px;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translate(0, -50%);
          width: 55px;
          text-align: center;

          @include sphoneP {
            font-size: 20px;
            line-height: 40px;
            width: 45px;
          }
        }
      }

      a {
        color: #343434;
        font-size: 24px;
        font-weight: 600;
        line-height: normal;

        @include sphoneP {
          font-size: 20px;
        }

        &[data-toggle="collapse"] {
          display: block;
          padding: 10px;
          text-decoration: none;
          position: relative;

          @include sphoneP {
            padding-right: 25px;
          }

          &:hover {
            background: transparent;
          }

          &::before,
          &::after {
            content: "";
            width: 20px;
            height: 0;
            border-top: #0071BE 1px solid;
            border-bottom: #0071BE 1px solid;
            position: absolute;
            right: 15px;
            top: 12px;
            bottom: 12px;
            margin: auto;

            @include sphoneP {
              right: 0;
            }
          }
        }

        &[aria-expanded=false]::after {
          -webkit-transform: rotate(90deg);
          -ms-transform: rotate(90deg);
          transform: rotate(90deg);
          transition-duration: 0.3s;
        }

        &[aria-expanded=true]::after {
          -webkit-transform: rotate(0deg);
          -ms-transform: rotate(0deg);
          transform: rotate(0deg);
          transition-duration: 0.3s;
        }
      }
    }

    >ul {
      margin-top: 30px;
    }

    [id^="menu"] li {
      font-size: 24px;
      padding: 10px;
      padding-left: 70px;
      position: relative;

      @include sphoneP {
        padding-left: 50px;
        font-size: 20px;
      }

      &::before {
        content: "A";
        color: #BE0000;
        border: 2px #BE0000 solid;
        border-radius: 50%;
        font-size: 30px;
        line-height: 50px;
        position: absolute;
        left: 0;
        top: 0;
        transform: translate(0, 15px);
        width: 55px;
        text-align: center;

        @include sphoneP {
          font-size: 20px;
          line-height: 40px;
          width: 45px;
        }
      }
    }
  }
}

/* ==[ #security_camera / 防犯・監視カメラ ]==================================== */
#security_camera {
  .sec-inner {
    padding-top: 0;
  }

  .iteme-wrap {
    .container-fluid {
      background: transparent
    }

    .card {
      border: 5px #E4F1F9 solid;
      flex-direction: row;
      padding: 50px;

      @include tabletL {
        flex-direction: column;
      }

      @include sphoneP {
        padding: 20px 15px;
      }
    }

    .card-img {
      width: 45%;
      height: auto;
      height: 100%;

      @include tabletL {
        width: 400%;
        margin: 0 auto 30px;
      }
    }

    .card-body {
      padding: 0 20px;

      @include sphoneP {
        padding: 0;
      }
    }

    .card-title {
      color: #0071BE;
      font-weight: 600;
      font-size: 22px;
      margin-bottom: 20px;

      @include sphoneP {
        font-size: 20px;
      }

    }

    .card-text {
      margin-bottom: 50px;
    }
  }

  .secondary {
    .card-img {
      border: 1px #707070 solid;
    }
  }

  .latest {
    background-color: #E4F1F9;

    .sec-inner {
      padding-top: 120px;

      @include tabletP {
        padding: 50px 0;
      }
    }

    .card {
      background-color: transparent;
      border: none;

    }

    .card-body {
      padding: 0;
      width: 100%;
      max-width: 100%;
    }

    .card-img-top {
      @include tabletP {
        margin-bottom: 20px;
        height: auto !important;
      }
    }

    .card-title {
      color: #0071BE;
      font-weight: 600;
      line-height: 2;
      margin-bottom: 30px;

      @include tabletP {
        margin-bottom: 10px;
      }
    }

    .card-text {
      font-style: 18px;

      @include tabletP {
        margin-bottom: 20px;
      }
    }

    .link-btn {
      background-color: #fff;

      &:hover {
        color: #fff;
      }
    }
  }
}

/* ==[ #company / 会社概要 ]==================================== */
#company {
  .card {
    border: none;
    background-color: transparent;
  }

  .card-img {
    @include sphoneP {
      margin-bottom: 20px;
    }
  }

  .card-text {
    font-size: 18px;
  }

  .message {

    .card-title {
      color: #00349D;
      font-size: 40px;
      font-family: 'Noto Serif JP',
        serif;
      margin-bottom: 30px;

      @include sphoneP {
        font-size: 30px;
      }
    }

    .card-img {
      @include sphoneP {
        width: 60%;
        margin: 0 auto;
      }
    }
  }

  .policy {
    .sec-inner {
      &.bg-color {
        background-color: #E4F1F9;
        padding: 50px 0;
      }
      &.bg-policy {
        background: url(../../../img/page/company/bgimage.png) no-repeat center center;
        padding: 50px 0;
      }
    }

    .card-title {
      color: #0071BE;
      font-size: 28px;
      margin-bottom: 10px;

      @include sphoneP {
        font-size: 20px;
      }

    }

    ul.policy-list {
      li {
        color: #0071BE;
        font-family: 'Noto Serif JP',
          serif;
        font-size: 18px;
        padding-left: 30px;
        margin-bottom: 10px;
        position: relative;

        &::before {
          content: " 一、";
          display: inline-block;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translate(0, -53%);
        }
      }
    }
  }

  .overview {
    .overview-tbl {
      border-top: 1px #B3B3B3 solid;
      border-bottom: 1px #B3B3B3 sold;
      border-collapse: collapse;
      display: table;
      margin-bottom: 50px;
      width: 100%;
      max-width: 100%;

      th,
      td {
        border-bottom: 1px #B3B3B3 solid;
        padding: 20px 50px;

        @include tabletP {
          padding: 20px 30px;
        }

        @include sphoneP {
          padding: 5px 10px;
          white-space: unset;
        }
      }

      th {
        white-space: nowrap;

        @include sphoneP {
          background-color: #e4f1f9;
          border-bottom: none;
          display: block;
          width: 100%;
        }
      }

      td {
        @include sphoneP {
          display: block;
          width: 100%;
        }
      }

    }

    .tel-link {
      color: #343434;
    }

    .gMap {
      iframe {
        width: 100%;
        max-width: 100%;
        height: 580px;

        @include sphoneP {
          height: 280px;
        }
      }
    }
  }

  .history-award {
    .col-md-6 {
      padding: 0;

      &:first-child {
        @include tabletP {
          margin-bottom: 50px;
        }
      }
    }

    .outer {
      border: 5px #e4f1f9 solid;
      padding: 50px;
      width: calc(100% - 30px);
      margin: 0 auto;

      @include sphoneP {
        padding: 20px 15px;
      }
    }

    .title-box {
      margin-bottom: 20px;
    }

    table.history-tbl,
    table.award-tbl {
      border-collapse: collapse;
      width: 100%;
      max-width: 100%;

      th,
      td {
        padding: 5px 10px;

        @include sphoneP {
          width: 100%;
          display: block;
        }

      }

      th {
        width: 90px;
        white-space: nowrap;

        @include sphoneP {
          width: 100%;
          white-space: unset;
        }
      }

      td {
        @include sphoneP {
          border-bottom: 1px #B3B3B3 solid;

        }
      }

    }

    table.award-tbl {
      th {
        @include sphoneP {
          border-bottom: none !important;
        }

      }

      td {
        text-align: right;

        @include sphoneP {
          text-align: left;
          width: 100%;

          &:first-of-type {
            border-bottom: none;
          }
        }
      }

      .last-cell {
        text-align: right;

        @include sphoneP {
          text-align: left;
          border-bottom: none !important;
        }
      }
    }
  }


}

/* ==[ #thermalCamera / 非接触検温対策 ]==================================== */
#thermalCamera {
  .sec-inner {
    padding-top: 0;
  }

  .products {
    .sec-inner {
      padding-top: 80px;
    }

    .card {
      border-color: #707070;
      padding: 10px 0;
    }

    .card-img-top {
      margin: 10px auto;
      width: calc(100% - 30px);
    }

    .card-title {
      color: #0071be;
      font-weight: 600;
      font-size: 22px;
      margin-bottom: 10px;
      text-align: center;
    }

    .card-footer {
      background-color: transparent;
      border-top: none;

      &.modContents {
        ul {
          padding-left: 0;
          margin-bottom: 0;

          li {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .flow {

    .down-arrow {
      display: inline-block;
      margin: 10px 0 50px;

      &:last-child {
        &::after {
          content: "";
          display: inline-block;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 25px 50px 0 50px;
          border-color: #2076bc transparent transparent transparent;
          ;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }

    .flow-lest {
      border: 5px #E4F1F9 solid;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      padding: 50px;
      margin-bottom: 20px;
      position: relative;

      @media screen and (max-width: 56em) {
        padding: 20px;
      }

      @media screen and (max-width: 36em) {
        flex-wrap: wrap;
      }


      >div {
        +div {
          margin-left: 50px;

          @media screen and (max-width: 56em) {
            margin-left: 30px;
          }

          @media screen and (max-width: 375px) {
            margin-left: 0;
            width: 100%;
          }
        }
      }

      .flow-lest-inn {
        width: 80%;

        @media screen and (max-width: 36em) {
          width: calc(60% - 20px)
        }
      }

      .list-num {
        display: inline-block;
        text-align: center;
        width: 20%;

        @media screen and (max-width: 36em) {
          width: calc(40% - 20px)
        }

        @media screen and (max-width: 375px) {
          margin-bottom: 20px;
          width: 100%;
        }

        p {
          border: 1px #0071BE solid;
          color: #0071BE;
          font-size: 18px;
          display: inline-block;
          padding: 0 10px;
          margin: 0 auto;
          width: 100%;
          max-width: 100%;

          @media screen and (max-width: 48em) {
            font-size: 16px;
          }

          &::before {
            content: "STEP";
            display: inline-block;
            margin-right: 5px;
            vertical-align: baseline;
          }
        }
      }

      .list-title {
        color: #333;
        display: inline-block;

        @media screen and (max-width: 375px) {
          text-align: center;
          width: 100%;
        }

        h4 {
          font-weight: 600;
          line-height: normal;
          margin-bottom: 0;
        }
      }

      .list-text {
        display: block;

        p {
          font-size: 20px;
        }
      }

      .list-text-sp {
        display: none;

        @media screen and (max-width: 36em) {
          display: block;
          margin-top: 10px;
          width: 100%;

        }

      }

    }
  }

  .support {
    background-color: #E4F1F9;

    .sec-inner {
      padding: 60px 0;
    }

    h6 {
      font-size: 18px;

      @include sphoneP {
        margin-bottom: 30px;
      }
    }
  }

  .scene {

    .sec-inner {
      padding-top: 120px;
    }

    .card-wrap {
      margin-bottom: 50px;
    }

    .card {
      border: none;

      @media screen and (max-width: 36em) {
        padding: 0 30px;
      }
    }

    .card-img {
      @media screen and (max-width: 36em) {
        margin-bottom: 20px;
      }

    }

    .card-title {
      color: #0071BE;
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 20px;
      display: flex;
      align-items: center;

      @include sphoneP {
        font-size: 18px;
      }

      >span {
        color: #00349D;
        font-size: 18px;
        display: inline-block;
        margin-right: 10px;
        white-space: nowrap;

        @include sphoneP {
          font-size: 16px;
        }
      }
    }

    .card-text {
      font-size: 18px;

      @include sphoneP {
        font-size: 16px;
      }
    }
  }
}


/* ==[ 画像切り取り ]==================================== */
.object-fit-img {
  object-fit: cover;
  object-position: center;
  font-family: 'object-fit: cover; object-position: center;'
}

/* ==[ reCAPTCHA ]==================================== */
.grecaptcha-badge {
  bottom: 60px!important;
}